import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ed83199"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Popout" }
const _hoisted_2 = { class: "Popout__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", {
        class: "Popout__btn_close",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args)))
      }),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}