
import { CONFIRM_OPEN } from "@/store/mutation-types";
import { Options, Vue } from "vue-class-component";
import { Prop, Emit } from "vue-property-decorator";
import { namespace } from "vuex-class";

const confirmModule = namespace("confirm");

@Options({
  components: {},
})
export default class Switch extends Vue {
  @Prop(Boolean) private value!: boolean;
  @Prop(String) private label!: string;
  @Prop(String) private labelDesc!: string;

  @Emit("change") private handleClickSwitch(): boolean {
    return !this.value;
  }

  @confirmModule.Action(CONFIRM_OPEN) private onOpen!: ({
    title,
    content,
  }: {
    title: string;
    content: string;
  }) => void;

  private handleLabelClicked(): void {
    this.onOpen({
      title: this.label,
      content: this.labelDesc,
    });
  }
}
