
import Popout from "@/components/widgets/Popout/Popout.vue";
import Switch from "@/components/widgets/Switch/Switch.vue";
import VqButton from "@/components/widgets/VqButton/VqButton.vue";
import VqInput from "@/components/widgets/VqInput/VqInput.vue";
import { TypeVqSwitch } from "@/components/widgets/VqSwitch";
import VqSwitch from "@/components/widgets/VqSwitch/VqSwitch.vue";
import { Options, Vue } from "vue-class-component";

@Options({
  components: { Popout, VqInput, VqSwitch, Switch, VqButton },
})
export default class StrategyRent extends Vue {
  private currentPeriodId = 0;
  private periodDict: TypeVqSwitch[] = [
    { id: 0, name: "选择天数" },
    { id: 1, name: "自定义天数" },
  ];

  // 自动续租
  private enableAutoRent = false;

  private onCloseDialog(): void {
    console.log("onCloseDialog");
    return;
  }
  private handlePeriodChanged(value: number | string) {
    this.currentPeriodId = Number(value);
  }

  private handleChange(val: boolean) {
    this.enableAutoRent = val;
  }
}
