
import { Options, Vue } from "vue-class-component";
import { Prop, Emit } from "vue-property-decorator";
import { TypeVqSwitch } from "./";

@Options({
  components: {},
})
export default class VqSwitch extends Vue {
  @Prop() private value!: number | string;
  @Prop({ type: Array, default: () => [] }) private opts!: TypeVqSwitch[];

  @Emit("change") private onClickedItem(val: number | string) {
    return val;
  }
}
