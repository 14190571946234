
import { Options, Vue } from "vue-class-component";
import { Prop, Emit } from "vue-property-decorator";

@Options({
  components: {},
})
export default class Popout extends Vue {
  @Prop(String) private title!: string;

  @Emit("close") private onClose(): void {
    return;
  }
}
