
import { Options, Vue } from "vue-class-component";
import Top from "@/components/widgets/Top/Top.vue";
import StrategyRent from "./Rent/Rent.vue";
import StrategyBenefit from "./StrategyBenefit.vue";
import StrategyCodeAdvantage from "./StrategyCodeAdvantage.vue";
import StrategyComment from "./StrategyComment.vue";
import StrategyDescription from "./StrategyDescription.vue";
import StrategyIntro from "./StrategyIntro.vue";

@Options({
  components: {
    Top,
    StrategyIntro,
    StrategyDescription,
    StrategyCodeAdvantage,
    StrategyBenefit,
    StrategyComment,
    StrategyRent,
  },
})
export default class StrategyDetail extends Vue {}
